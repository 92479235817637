.subpage {
    background-color: #fff;
    padding-bottom: 50px; 
}

.subpage-hero {
    background: url('/public/assets/images/subpage.png');
    width: 100%;
    min-height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
}

@media only screen and (max-width: 576px) {
    .subpage-hero {
        background: url('/public/assets/images/subpage.png');
        width: 100%;
        min-height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom right -370px;
    }
}