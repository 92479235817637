nav {
    background-color: rgba(0, 0, 0, 0.425);
    width: 100%;
    padding: 10px 100px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
}

.nav-item {
    color: #1F1F1F!important;
}

.nav-logo {
    height: 40px;
    margin-right: 25px;
}

.menu {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #E9975C -62.84%, #562A6B 100%);
    position: fixed;
    z-index: 99;
    padding: 5px 25px;
}

.menu li {
    color: #fff;
    text-align: center;
    list-style-type: none;
    padding: 5px;
}

.menu a {
    color: #fff;
}

@media only screen and (max-width: 576px) {
    nav {
        padding: 5px 25px;
    }
}

