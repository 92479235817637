* {
    word-wrap: break-word;
}

.bright {
    color: #fff;
}

.dark {
    color: #1F1F1F;
}

.bold {
    font-weight: bold!important;
}

.underline {
    transition: all ease-in-out;  
}

.underline:hover {
    text-decoration: underline;
    transition: all ease-in-out; 
}

.no-padding {
    padding: 0!important;
}

.h1, .h2, h3, h4, .p, .breadcrumbs {
    padding: 10px 0px;
}

@media only screen and (max-width: 576px) {
    .h1 {
        font-size: 25px!important;
        text-align: center;
    }

    .h2 {
        font-size: 20px!important;
    }

    .mobile .h2 {
        font-size: 30px!important;
        text-align: left;
    }

    .h3 {
        font-size: 25px!important;
        text-align: left; 
    }
    
    .p {
        font-size: 15px!important;
        text-align: left;
    }

    .mobile .p {
        text-align: left;
    }
    
    .breadcrumbs {
        font-size: 25px!important;
        text-align: left;
    }
}

@media only screen and (min-width: 576px) {
    .h1, .breadcrumbs {
        font-size: 30px!important;
        text-align: left;
    }

    .h2 {
        font-size: 25px!important;
        text-align: left;
    }

    .h3 {
        font-size: 20px!important;
        text-align: left; 
    }
}

@media only screen and (min-width: 768px) {
    .h1, .breadcrumbs {
        font-size: 35px!important;
        text-align: left;
    }

    .h2 {
        font-size: 28px!important;
        text-align: left;
    }
}

@media only screen and (min-width: 992px) {
    .h1, .breadcrumbs {
        font-size: 40px!important;
        text-align: left;
    }
}

@media only screen and (min-width: 1200px) {
    .h1, .breadcrumbs {
        font-size: 48px!important;
        text-align: left;
    }

    .h2 {
        font-size: 30px!important;
        text-align: left;
    }

    .h3 {
        font-size: 25px!important;
        text-align: left; 
    }

    .h4 {
        font-size: 20px!important;
        text-align: left;  
    }

    .p {
        font-size: 15px!important;
        text-align: left;
    }
}

