.hero {
    background: url('/public/assets/images/background.webp');
    background-repeat: no-repeat;
    background-position: bottom 70px center;
    background-size: cover;
    width: 100%;
    height: 100vh;
    min-height: 1080px;
    object-fit: cover;
}

#tsparticles {
    position: absolute!important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 0;
    height: 100%
}

.no-particles {
    position: relative;
    z-index: 1;
}

.about {
    background: linear-gradient(180deg, #E9975C -62.84%, #562A6B 100%);
    padding-bottom: 150px;
}

.about-elements {
    margin-top: -150px!important;
}

@media only screen and (max-width: 900px) {
    .about-elements:nth-child(2) {
        margin-top: 0px!important;
    }

    .hero {
        min-height: 800px;
    }
}

.offer {
    background: url('/public/assets/images/foreground.webp');
    background-repeat: no-repeat;
    margin-bottom: 100px;
}

.offer-elements {
    margin-top: -100px!important;
}

* {
    scrollbar-color: rebeccapurple green!important;
}

.snap {
    display: flex;
    overflow-x: scroll; /*Ukryć scrolla dla desktopów */
    padding: 0px 25px 0px 0px;
    scroll-snap-type: x mandatory;
    scroll-padding: 25px;
    width: 100%!important;
    scrollbar-color: rebeccapurple green!important;
}

.snap .item {
    padding-left: 25px;
    scroll-snap-align: start;
}

@media only screen and (max-width: 900px) {
    .snap .item {
        width: 300px;
    }
}

.workshops-image {
    margin-top: -50px;
}

.testimonials__container {
    width: 40%;
    padding-bottom: 4rem;
  }
  .client__avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary);
  }
  
  .testimonials {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none; /* to not allow user to select the text */
  }
  
  .client_review {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
  }
  .swiper-pagination-clickable .swiper-pagination-bullet-active {
    background: var(--color-primary);
  }
  /* ======media queries for tablets(medium devices) =========*/
  
  @media screen and (max-width: 1024px) {
    .testimonials__container {
      width: 60%;
    }
  }
  
  /* ======media queries for mobile(small devices) =========*/
  
  @media screen and (max-width: 600px) {
    .testimonials__container {
      width: var(--container-width-sm);
    }
    .client_review {
      width: var(--container-width-sm);
    }
  }