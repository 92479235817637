button {
    margin: 10px 20px 0px 0px!important;
    padding: 5px 20px!important;
    text-transform: none!important;
}

.primary {
    background: linear-gradient(180deg, #E9975C 0%, #562A6B 100%)!important;
}

.outline {
    background-color: #fff!important;
    border: 1px solid #000!important;
}

.secondary {
    background-color: #fff!important;
}

.purple {
    background-color: #fff!important;
    color: #4C145C!important;
    border: 1px solid #4C145C!important;
}

.purple:hover {
    background-color: #B291C1!important;
    color: #4C145C!important;
    border: 1px solid #B291C1!important;
}

.blue {
    background-color: #fff!important;
    color: #164C69!important;
    border: 1px solid #164C69!important;
}

.blue:hover {
    background-color: #93CAE9!important;
    color: #164C69!important;
    border: 1px solid #93CAE9!important;
}

.pinkngreen {
    color: #1D5F1C!important; 
    background-color: #fff!important;
    border: 1px solid #1D5F1C!important;
}

.pinkngreen:hover {
    background: linear-gradient(180deg, #FFACF2 0%, #98DE97 100%)!important;
    border-image: linear-gradient(45deg, #FFACF2, #98DE97)!important;
    border: 0px solid!important;
}

.yellow {
    color: #716509!important; 
    background-color: #fff!important;
    border: 1px solid #716509!important;
}

.yellow:hover {
    background: linear-gradient(180deg, #F8F1BB 0%, #E9E19E 100%)!important;
    border-image: linear-gradient(45deg, #F8F1BB, #E9E19E)!important;
    border: 0px solid!important;
}

.orange {
    color: #6C470E!important; 
    background-color: #fff!important;
    border-image: linear-gradient(45 deg, #EF7D7D, #EFC17D)!important;
    border: 1px solid!important;
}

.orange:hover {
    background: linear-gradient(180deg, #EF7D7D 0%, #EFC17D 100%)!important;
    border-image: linear-gradient(45deg, #EF7D7D, #EFC17D)!important;
    border: 0px solid!important;
}

.full-width-button {
    margin-top: -74px!important;
    width: 100%;
}