@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif!important;
}

.reset {
  margin: 0!important;
  padding: 0!important;
}

.snack button {
  margin: 0!important;
  padding: 0!important;
}

.loader {
  background: linear-gradient(180deg, #E9975C 0%, #562A6B 100%);
}

html, body, #root {
  height: 100%;
}

.App {
  height: 100%;
}

/* .App ::-webkit-scrollbar {
  height: 0px;
  width: 10px;
  background: #fff;
  display: none;
} */

body {
  background-color: #FAFAFA;
}

.carousel .rec {
  column-gap: 10px;
}

.rec-carousel-wrapper .rec-carousel {
  display: flex;
}

.rec button.kXteup {
  height: 'auto'!important;
  width: 'auto'!important
}

ul, ol {
  padding-left: 18px;
}

a {
  text-decoration: none;
  color: #1976d2;
}

.center {
  text-align: center!important;
}

.right {
  text-align: right!important;
}

.py-5 {
  padding-top: 50px!important;
  padding-bottom: 50px!important;
}

.py-1 {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}

.px-2 {
  padding: 0px 25px;
}

.pt-0 {
  padding-top: 0px!important;
}

.pt-2 {
  padding-top: 20px!important;
}

.pt-5 {
  padding-top: 50px!important;
}

.pt-10 {
  padding-top: 100px!important;
}

.pb-5 {
  padding-bottom: 50px!important;
}

.pb-7 {
  padding-bottom: 70px!important;
}

.ml-25 {
  margin-left: 25%!important;
}

.w-full {
  width: 100%!important;
}

.h-full {
  height: 100%!important;
}

.w-50 {
  width: 50%!important;
}

.d-flex {
  display: flex;
}

.bg-white {
  background-color: #fff!important;
}

.white-font {
  color: #fff!important;
}

.absolute {
  position: absolute;
}

.flex-column {
  flex-direction: column;
}

.container {
  height: 100%;
  padding: 0px 100px;
}

.container-snap {
  height: 100%;
  padding: 0px 100px 0px 100px;
}

.footer a span,
.footer a {
  color: #1F1F1F;
}

.multiple-section-background {
  background: url('/public/assets/images/extended-foreground.webp');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 250px;
}

.materials {
  margin-top: -250px;
  padding-top: 250px;
}

.contactform {
  margin-top: -250px;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.align-items-end {
  align-items: end;
}

.justify-space-between {
  justify-content: space-between;
}

@media only screen and (max-width: 576px) {
  .container {
    padding: 0px 25px;
  }

  .container-snap {
    height: 100%;
    padding: 0px 0px 0px 25px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .align-center-on-mobile {
    text-align: center!important;
  }
}

@media only screen and (min-width: 576px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
