.basic-paper {
    padding: 25px;
}

.basic-paper-image {
    margin-bottom: -25px;
}


.responsive-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
}

.responsive-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.contact iframe {
    border: none;
    height: 100%;
    width: 100%;
}

.mobile .responsive-iframe {
    margin-left: -25px;
    width: 100vw;
}

.highlighted::after {
    content: '';
    position: absolute;
    top: -5px; bottom: -5px;
    left: -5px; right: -5px;
    background: linear-gradient(180deg, #E9975C 0%, #562A6B 100%);
    z-index: -1;
    border-radius: 5px;
}

.phone a, .mail a {
    color: white;
    padding-left: 5px;
}